import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Filter } from '@/types/filter';
import { createCheck } from '@/assets/icons';
import { Button, Icon } from '..';
import { Popover } from '../popover';

export type FilterByProps = StyleProps & {
  /**
   * Whether the filter by select is disabled
   * @default false
   */
  isDisabled?: boolean;

  /**
   * The filter by option
   */
  selectedFilter?: Filter;

  /** Handler to call when the user selects a filter */
  onSelectFilter?: (selectedFilter: Filter) => void;

  /**
   * The options to display in the select
   */
  filterOptions: Filter[];

  /** Function to call to get display name for each filter option */
  getDisplayName(filter: Filter): string;

  /**
   * Filter by label
   * @default 'Filter By'
   */
  label?: string;
};

const ROOT = makeRootClassName('FilterBy');
const el = makeElementClassNameFactory(ROOT);

const SELECTED_ICON = createCheck;

const DEFAULT_PROPS = {
  isDisabled: false,
  label: 'Filter By',
} as const;

const FilterByOptionRow = (props: {
  option: string;
  isSelected: boolean;
  onSelect?: (selectedFilter: string) => void;
  getDisplayName(filter: string): string;
}): ReactElement => {
  const { hoverProps, isHovered } = useHover({ isDisabled: false });
  const { pressProps, isPressed } = usePress({
    isDisabled: false,
    onPress: () => props.onSelect?.(props.option),
  });
  const behaviorProps = mergeProps(hoverProps, pressProps);

  return (
    <div
      {...behaviorProps}
      className={clsx(el`option`, {
        'is-selected': props.isSelected,
        'is-hovered': isHovered,
        'is-pressed': isPressed,
      })}
    >
      <span className={el`label`}>{props.getDisplayName(props.option)}</span>
      {props.isSelected && (
        <Icon content={SELECTED_ICON} className={el`selected-icon`} />
      )}
    </div>
  );
};

function FilterBy(props: FilterByProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);

  const options = p.filterOptions.map((option, idx) => (
    <FilterByOptionRow
      key={`${option}-${idx}`}
      option={option}
      isSelected={p.selectedFilter ? p.selectedFilter === option : false}
      onSelect={(option) => {
        p.onSelectFilter?.(option as Filter);
      }}
      getDisplayName={p.getDisplayName}
    />
  ));

  return (
    <div className={clsx(ROOT, p.className)}>
      <Popover
        content={options}
        open={open}
        onOpenChange={setOpen}
        hasReactAriaChildren
      >
        <Button
          variant="text-blue"
          size="xs"
          isDropdown
          isDisabled={p.isDisabled}
          className={el`filter-by-button`}
        >
          {p.label}
        </Button>
      </Popover>
    </div>
  );
}

export default FilterBy;
