import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { Button } from '@/components';
import { useAuthRequiredAction } from '@/hooks';
import { FILE_REPORT_ROUTE } from '@/types/routes';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type FileReportButtonProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('FileReportButton');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function FileReportButton(props: FileReportButtonProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { isLoggedIn, openAuthModal } = useAuthRequiredAction();
  const router = useRouter();

  return (
    <Button
      variant="secondary"
      className={clsx(ROOT, p.className)}
      onPress={() => {
        if (
          isLoggedIn ||
          !!process.env.NEXT_PUBLIC_REQUIRE_AUTH_TO_REPORT_ENABLED
        ) {
          router.push(FILE_REPORT_ROUTE);
        } else {
          openAuthModal();
        }
      }}
    >
      File new report
    </Button>
  );
}

export default FileReportButton;
