import clsx from 'clsx';
import { ReactElement } from 'react';
import { ChainIcon, Text } from '@/components';
import { Badge } from '@/components/badge';
import { getDisplayStringForChainType } from '@/types/chain';
import {
  CategoryFilterOption,
  ChainFilterOption,
  Filter,
} from '@/types/filter';
import { getDisplayStringForScamCategory } from '@/types/scam-categories';
import {
  buttonize,
  formatReportCount,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

type CommonFilterTableProps = {
  /** Optional title to override */
  title?: string;

  onSelectOption?: (filter: Filter) => void;

  /** The selected filter. */
  selectedFilter?: Filter;

  /** Whether to show the no filters view @default false */
  showNoFilters?: boolean;
};

export type ChainFilterTableProps = {
  type: 'chain';
  options: ChainFilterOption[];
};

export type CategoryFilterTableProps = {
  type: 'category';
  options: CategoryFilterOption[];
};

export type FilterTableProps = StyleProps &
  CommonFilterTableProps &
  (ChainFilterTableProps | CategoryFilterTableProps);

const ROOT = makeRootClassName('FilterTable');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  showNoFilters: false,
} as const;

function FilterTable(props: FilterTableProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const isChain = p.type === 'chain';

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="h4" className={el`title`}>
        {p.title ?? `Reports by ${isChain ? 'Chain' : 'Category'}`}
      </Text>
      <div className={el`options`}>
        {!p.showNoFilters && (
          <>
            {isChain
              ? p.options.map((option, index) => (
                  <div
                    key={`${option.filter}`}
                    className={clsx(el`filter-option`, {
                      'is-selected': p.selectedFilter === option.filter,
                    })}
                    {...buttonize(() => p.onSelectOption?.(option.filter))}
                  >
                    <div className={el`chain-name`}>
                      <ChainIcon size="small" chainType={option.filter} />
                      <Text type="body-lg">
                        {getDisplayStringForChainType(option.filter)}
                      </Text>
                    </div>
                    <Badge size="small" className={el`num-reports`}>
                      {formatReportCount(option.numResults)}
                    </Badge>
                  </div>
                ))
              : p.options.map((option) => (
                  <div
                    key={`${option.filter}`}
                    className={clsx(el`filter-option`, {
                      'is-selected': p.selectedFilter === option.filter,
                    })}
                    {...buttonize(() => p.onSelectOption?.(option.filter))}
                  >
                    <Text type="body-lg">
                      {getDisplayStringForScamCategory(option.filter)}
                    </Text>
                    <Badge size="small" className={el`num-reports`}>
                      {formatReportCount(option.numResults)}
                    </Badge>
                  </div>
                ))}
          </>
        )}
        {p.showNoFilters && (
          <div className={el`filter-option`}>
            <Text type="body-lg">No reports</Text>
          </div>
        )}
      </div>
    </div>
  );
}
export default FilterTable;
