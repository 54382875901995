import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

type ReactChildren = {
  children?: ReactNode;
};

type ChildrenAndStyleProps = ReactChildren & StyleProps;

export type LayoutRootProps = StyleProps &
  ReactChildren & {
    /**
     * Whether the layout is for an empty set of results
     * @default false
     */
    isNoResults?: boolean;
  };

const ROOT = makeRootClassName('ResultsSectionLayout');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

/**
 * Expects main and sidebar as children.
 */
function Root(props: LayoutRootProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <section
      className={clsx(ROOT, { 'is-no-results': p.isNoResults }, p.className)}
    >
      <div className={el`content`}>{p.children}</div>
    </section>
  );
}

function Main(props: ChildrenAndStyleProps): ReactElement {
  return (
    <div className={clsx(el`main`, props.className)}>{props.children}</div>
  );
}

function Sidebar(props: ChildrenAndStyleProps): ReactElement {
  return (
    <div className={clsx(el`sidebar`, props.className)}>{props.children}</div>
  );
}

function SidebarFilters(props: ChildrenAndStyleProps): ReactElement {
  return (
    <div className={clsx(el`sidebar-filters`, props.className)}>
      {props.children}
    </div>
  );
}

function ResultsHeader(props: ChildrenAndStyleProps): ReactElement {
  return (
    <div className={clsx(el`header`, props.className)}>{props.children}</div>
  );
}

function Results(props: ChildrenAndStyleProps): ReactElement {
  return (
    <div className={clsx(el`results`, props.className)}>{props.children}</div>
  );
}

export default { Root, Main, Sidebar, SidebarFilters, ResultsHeader, Results };
