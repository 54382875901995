import {
  useChainsQuery,
  ChainsQueryResult,
  ChainsInput,
} from '@/generated/graphql';

export const useChains = (
  input: ChainsInput = {}
): NonNullable<ChainsQueryResult['data']>['chains'] | null => {
  const { data } = useChainsQuery({
    variables: {
      input,
    },
  });

  return data?.chains ?? null;
};
