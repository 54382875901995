import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { getLabelForOption, SortByOption } from '@/types/sort-by';
import { createCheck } from '@/assets/icons';
import { Button, ButtonProps, Icon } from '@/components';
import { Popover } from '../popover';
import { OptionalTooltip } from '../tooltip/Tooltip';

export type SortBySelectProps = StyleProps & {
  /**
   * Whether the sort by select is disabled
   * @default false
   */
  isDisabled?: boolean;

  /**
   * The selected sort by option
   * @default SortByOption.NEWEST
   */
  selectedSort?: SortByOption;

  options?: SortByOption[];

  /** Handler to call when the user selects a sort */
  onSelectSort?: (selectedSort: SortByOption) => void;

  /**
   * The action label to display in the button
   * @default 'Sort by'
   */
  label?: string;

  /**
   * The action button's variant
   * @default 'text-blue'
   */
  variant?: ButtonProps['variant'];

  /**
   * The tooltip label
   */
  tooltipLabel?: string;
};

const ROOT = makeRootClassName('SortBySelect');
const el = makeElementClassNameFactory(ROOT);

const SELECTED_ICON = createCheck;

const DEFAULT_PROPS = {
  isDisabled: false,
  selectedSort: SortByOption.NEWEST,
  label: 'Sort by',
  variant: 'text-blue',
  tooltipContent: 'Sort',
} as const;

const SortByOptionRow = (props: {
  option: SortByOption;
  isSelected: boolean;
  onSelect?: (selectedSort: SortByOption) => void;
}): ReactElement => {
  const { hoverProps, isHovered } = useHover({ isDisabled: false });
  const { pressProps, isPressed } = usePress({
    isDisabled: false,
    onPress: () => props.onSelect?.(props.option),
  });
  const behaviorProps = mergeProps(hoverProps, pressProps);

  return (
    <div
      {...behaviorProps}
      className={clsx(el`option`, {
        'is-selected': props.isSelected,
        'is-hovered': isHovered,
        'is-pressed': isPressed,
      })}
    >
      <span className={el`label`}>{getLabelForOption(props.option)}</span>
      {props.isSelected && (
        <Icon content={SELECTED_ICON} className={el`selected-icon`} />
      )}
    </div>
  );
};

function SortBySelect(props: SortBySelectProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);

  const options = p.options ?? Object.values(SortByOption);

  const content = options.map((option, idx) => (
    <SortByOptionRow
      key={`${option}-${idx}`}
      option={option}
      isSelected={option === p.selectedSort}
      onSelect={(option) => {
        p.onSelectSort?.(option);
      }}
    />
  ));

  return (
    <div className={clsx(ROOT, p.className)}>
      <OptionalTooltip variant="block" content={p.tooltipLabel} isInstant>
        <div>
          <Popover
            content={content}
            open={open}
            onOpenChange={setOpen}
            hasReactAriaChildren
          >
            <Button
              variant={p.variant}
              size="xs"
              isDropdown
              isDisabled={p.isDisabled}
              className={el`sort-by-button`}
              light
            >
              {p.label}
            </Button>
          </Popover>
        </div>
      </OptionalTooltip>
    </div>
  );
}

export default SortBySelect;
