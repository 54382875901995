import clsx from 'clsx';
import { ReactElement } from 'react';
import { Badge, Text } from '@/components';
import { useScamCategories } from '@/features/home-page/hooks';
import { getRouteForCategory } from '@/types/routes';
import {
  getDisplayStringForScamCategory,
  ScamKindWithReportsCount,
} from '@/types/scam-categories';
import {
  formatReportCount,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ReportsByCategoryNavProps = StyleProps & {
  scamCategories: ScamKindWithReportsCount[] | null;
};

const ROOT = makeRootClassName('ReportsByCategoryNav');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function ReportsByCategoryNav(
  props: ReportsByCategoryNavProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  // Done because sort mutates the state directly below
  // @see https://stackoverflow.com/a/66721870
  const arrayForSort = Array.isArray(props.scamCategories)
    ? [...props.scamCategories]
    : null;

  const sortedCategories = arrayForSort?.sort((a, b) => {
    if ((a.kind === 'OTHER') != (b.kind === 'OTHER')) {
      return a.kind === 'OTHER' ? 1 : -1;
    }
    return b.reportsFiledCount - a.reportsFiledCount;
  });

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="h4" className={el`title`}>
        Reports by Category
      </Text>
      <div className={el`categories`}>
        {sortedCategories?.map((category, index) => (
          <a
            href={getRouteForCategory(category.kind)}
            key={`${category}-${index}`}
            className={el`category`}
          >
            <Text type="body-lg">
              {getDisplayStringForScamCategory(category.kind)}
            </Text>
            <Badge size="small" className={el`num-reports`}>
              {formatReportCount(category?.reportsFiledCount ?? 0)}
            </Badge>
          </a>
        ))}
      </div>
    </div>
  );
}

function ReportsByCategoryNavContainer(props: StyleProps): ReactElement {
  const { allScamCategoriesWithReports } = useScamCategories();

  return (
    <ReportsByCategoryNav
      {...props}
      scamCategories={allScamCategoriesWithReports}
    />
  );
}

export default ReportsByCategoryNavContainer;
