import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { Text } from '@/components';
import { useChains } from '@/features/home-page/hooks';
import {
  ChainType,
  getDisplayStringForChainType,
  getTickerForChain,
} from '@/types/chain';
import { getRouteForChain } from '@/types/routes';
import {
  buttonize,
  formatReportCount,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { getChainsOrderedByReports } from '@/utils/chains';

export type ReportsByChainNavProps = StyleProps & {
  /**
   * Function to call to get number of reports filed for a chain
   */
  getReportedCountForChain: (chain: ChainType) => number;
};

type ReportsForChainProps = {
  chainType: ChainType;
  reportsCount: number;
  onAction: () => void;
};

const ROOT = makeRootClassName('ReportsByChainNav');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ReportsForChainLink(props: ReportsForChainProps): ReactElement {
  const { chainType, reportsCount, onAction } = props;
  return (
    <div
      {...buttonize(onAction)}
      className={clsx(el`chain-link`, `chain-${getTickerForChain(chainType)}`)}
    >
      <div className={el`chain-info`}>
        <Text type="body-lg" className={el`chain-title`}>
          {getDisplayStringForChainType(chainType)}
        </Text>
        <Text type="body-sm" className={el`reports-count`}>
          {formatReportCount(reportsCount)}
        </Text>
      </div>
    </div>
  );
}

function ReportsByChainNav(props: ReportsByChainNavProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const router = useRouter();
  const chains = getChainsOrderedByReports(p.getReportedCountForChain);

  return (
    <div className={clsx(ROOT, p.className)}>
      <h2 className={el`title`}>Reports by Chain</h2>
      {chains.map((chain, i) => (
        <ReportsForChainLink
          key={i}
          chainType={ChainType[chain]}
          reportsCount={p.getReportedCountForChain(ChainType[chain])}
          onAction={() => router.push(getRouteForChain(ChainType[chain]))}
        />
      ))}
      {/* <ReportsForChainLink
            chainType={ChainType.CELO}
            reportsCount={p.getReportedCountForChain(ChainType.CELO)}
            onAction={() => router.push(getRouteForChain(ChainType.CELO))}
          /> */}
    </div>
  );
}

function ReportsByChainNavContainer(props: StyleProps): ReactElement {
  const chains = useChains();

  const getReportedCountForChain = (chain: ChainType): number => {
    const chainData = chains?.find((c) => c.kind === chain);
    return chainData?.reportsFiledCount ?? 0;
  };

  return (
    <ReportsByChainNav
      {...props}
      getReportedCountForChain={getReportedCountForChain}
    />
  );
}
export default ReportsByChainNavContainer;
