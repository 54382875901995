import { usePagination } from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Tablet } from '@/utils/responsive';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { createChevronLeft, createChevronRight } from '@/assets/icons';
import { Text } from '..';
import Button from '../button/ButtonBase';
import type {
  UsePaginationItem,
  UsePaginationProps,
} from '@material-ui/lab/Pagination';

export type PageSelectProps = StyleProps &
  Pick<UsePaginationProps, 'count' | 'onChange' | 'page'> & {
    /** Whether the page select is disabled */
    isDisabled?: boolean;
    /** What number the first result on this page is of the total results */
    firstResultOnPageNum: number;
    /** What number the last result on this page is of the total results */
    lastResultOnPageNum: number;
    /** The total number of results across all pages */
    numResults: number;
  };

const ROOT = makeRootClassName('PageSelect');
const el = makeElementClassNameFactory(ROOT);

const NEXT_ICON_PATH = createChevronRight;
const PREVIOUS_ICON_PATH = createChevronLeft;

const DEFAULT_PROPS = {
  isDisabled: false,
} as const;

function renderItem(item: UsePaginationItem): ReactElement {
  const { type, page, selected, disabled } = item;
  switch (type) {
    case 'start-ellipsis':
    case 'end-ellipsis':
      return <span className={el`ellipsis`}>...</span>;
    case 'page':
      return (
        <Button
          {...item}
          type="button"
          size="small"
          className={el`page`}
          isSelected={selected}
          isDisabled={disabled}
          variant="secondary"
          isOutline
        >{`${page}`}</Button>
      );
    case 'next':
      return (
        <Button
          {...item}
          type="button"
          size="small"
          isDisabled={disabled}
          className={el`arrow`}
          startIcon={NEXT_ICON_PATH}
          isOutline
          variant="secondary"
        />
      );
    case 'previous':
      return (
        <Button
          {...item}
          type="button"
          isDisabled={disabled}
          size="small"
          className={el`arrow`}
          startIcon={PREVIOUS_ICON_PATH}
          isOutline
          variant="secondary"
        />
      );
    default:
      return <></>;
  }
}

function PageSelect(props: PageSelectProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  // state

  const { items } = usePagination({
    ...p,
    siblingCount: 1,
    boundaryCount: 1,
    disabled: p.isDisabled,
  });

  // Remove the last page from the page selector since our pagination model
  // doesn't easily support selecting it
  let foundEndEllipsis = false;
  const renderedItems = items.reduce(
    (prev: UsePaginationItem[], next): UsePaginationItem[] => {
      if (next.type === 'page' && foundEndEllipsis) {
        return prev;
      } else if (next.type === 'end-ellipsis') {
        foundEndEllipsis = true;
      }
      return [...prev, next];
    },
    []
  );
  // const renderedItems = items;

  return (
    <nav className={clsx(ROOT, p.className)}>
      <Tablet>
        <Text
          className={el`results-label`}
          type="body-md"
        >{`${p.firstResultOnPageNum} - ${p.lastResultOnPageNum} of ${p.numResults} items`}</Text>
      </Tablet>
      {renderedItems.map((item, index) => {
        return <li key={index}>{renderItem(item)}</li>;
      })}
    </nav>
  );
}

export default PageSelect;
