import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import {
  Badge,
  CustomContentLoader,
  FilterBy,
  PageSelect,
  SortBySelect,
  Text,
} from '@/components';
import { PageSelectProps } from '@/components/page-select/PageSelect';
import { ScamReportCard, ScamReportCardProps } from '@/features';
import { useResults, UseResultsOptions } from '@/hooks';
import { getDisplayStringForChainType } from '@/types/chain';
import {
  CategoryFilterOption,
  ChainFilterOption,
  Filter,
} from '@/types/filter';
import { PageType } from '@/types/page';
import { getRouteForReport } from '@/types/routes';
import {
  getDisplayStringForScamCategory,
  ScamCategory,
} from '@/types/scam-categories';
import { ScamReport } from '@/types/scam-report';
import type { SortByOption } from '@/types/sort-by';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { getScamReportCardPropsFromReport } from '@/utils/getScamReportCardPropsFromReport';
import { Tablet } from '@/utils/responsive';
import { PRIVATE_REPORT_DUMMY_DETAILS } from '@/dummy/results';
import {
  FileReportButton,
  FilterTable,
  Layout,
  ReportsByCategoryNav,
  ReportsByChainNav,
} from './components';
import { getHeaderParamsForResults, getSortFilterProps } from './utils';

export type ResultsSectionProps = StyleProps &
  Pick<PageSelectProps, 'firstResultOnPageNum' | 'lastResultOnPageNum'> & {
    /** The type of page this results section is being rendered on */
    pageType:
      | PageType.SEARCH_ADDRESS
      | PageType.SEARCH_DOMAIN
      | PageType.PROFILE
      | PageType.PROFILE_SELF
      | PageType.PROFILE_OTHER
      | PageType.BROWSE_ALL
      | PageType.BROWSE_CATEGORY
      | PageType.BROWSE_CHAIN
      | PageType.REPORT_BY_ID
      | PageType.MY_FEED
      | PageType.SUBDOMAIN_FEED;

    /** The total number of reports for this search or browse query */
    numReports: number;
    /** The total number of result pages in the pagination of results */
    numPages: number;
    /** The result page number the user is on right now */
    currentPage: number;
    /** The results to display on this page */
    results: ScamReport[];
    /** The sort that's been applied to the results */
    selectedSort: SortByOption;
    /** The filter that has been applied */
    selectedFilter?: Filter;

    /**
     * Whether to show the table to filter by chain
     * @default false
     */
    showChainFilterOptions?: boolean;

    /**
     * The chain filter options for the results page
     * @default []
     */
    chainFilterOptions?: ChainFilterOption[];

    /**
     * Whether to show the table to filter by category
     * @default false
     */
    showCategoryFilterOptions?: boolean;

    /**
     * The category filter options for the results page.
     * @default []
     */
    categoryFilterOptions?: CategoryFilterOption[];

    /**
     * Whether to show the side bar nav links to reports by chain.
     * @default false
     */
    showReportsByChainNav?: boolean;

    /**
     * Whether to show the side bar nav links to reports by category.
     * @default false
     */
    showReportsByCategoryNav?: boolean;

    /**
     * Whether to show loading indicators while the results are being fetched
     * @default false
     */
    isLoading?: boolean;

    /** Handler to call when the user changes the sort selected */
    onSelectSort: (selectedSort: SortByOption) => void;
    /** Handler to class when the user changes the pagination page */
    onSelectPage?: (page: number) => void;
    /** Handler to call when the user adds a filter */
    onAddFilter?: (filter: Filter) => void;
    /** Handler to call when the user removes the filter */
    onRemoveFilter?: () => void;

    error?: string;

    /**
     * Whether to show the keywords in the sidebar
     * @default false
     */
    showKeywords?: boolean;

    /**
     * The keywords to show in the sidebar
     */
    keywords?: string[];
  };

export type SortFilterProps = Pick<
  ResultsSectionProps,
  | 'selectedFilter'
  | 'onRemoveFilter'
  | 'onAddFilter'
  | 'selectedSort'
  | 'onSelectSort'
  | 'showChainFilterOptions'
  | 'chainFilterOptions'
  | 'showCategoryFilterOptions'
  | 'categoryFilterOptions'
>;

const ROOT = makeRootClassName('ResultsSection');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  showChainFilterOptions: false,
  chainFilterOptions: [] as ChainFilterOption[],
  showCategoryFilterOptions: false,
  categoryFilterOptions: [] as CategoryFilterOption[],
  showReportsByChainNav: false,
  showReportsByCategoryNav: false,
  showKeywords: false,
  isLoading: false,
} as const;

// utils

const getNoResultsMessage = (pageType: PageType): string => {
  switch (pageType) {
    case PageType.SEARCH_ADDRESS:
      return 'Unreported addresses might still be used in scams so caution is advised. If you suspect this address is being used in a scam, please report it.';
    case PageType.SEARCH_DOMAIN:
      return 'Unreported domains might still be used in scams so caution is advised. If you suspect this domain is being used in a scam, please report it.';
    case PageType.BROWSE_CHAIN:
      return 'No reports found for this chain. Unreported chains might still be used in scams so caution is advised. If you suspect this chain is being used in a scam, please report it.';
    case PageType.BROWSE_CATEGORY:
      return 'No reports found for this category. There are still scams of this nature so caution is advised. If you suspect a scam in this category, please report it.';
    case PageType.PROFILE_OTHER:
      return `This user hasn't submitted any Scam Reports`;
    case PageType.PROFILE_SELF:
      return `You haven't submitted any Scam Reports. If you suspect an address is being used in a scam, please report it.`;
    default:
      return 'There are no scams reported.';
  }
};

function ResultsTitle(props: { children: string }) {
  return <h3 className={el`results-title`}>{props.children}</h3>;
}

function ResultsSelectedFilter(
  props: Pick<ResultsSectionProps, 'selectedFilter' | 'onRemoveFilter'>
) {
  const { selectedFilter, onRemoveFilter } = props;

  const isCategoryFilter = (filter: any): filter is ScamCategory =>
    Object.values(ScamCategory).includes(filter);

  let displayFilter = '';
  if (selectedFilter !== undefined) {
    displayFilter = isCategoryFilter(selectedFilter)
      ? getDisplayStringForScamCategory(selectedFilter)
      : getDisplayStringForChainType(selectedFilter);
  }

  return (
    <div className={el`selected-filters`}>
      {selectedFilter !== undefined && (
        <Badge
          key={selectedFilter}
          size="medium"
          isDismissible
          onDismiss={() => onRemoveFilter?.()}
        >
          {displayFilter}
        </Badge>
      )}
    </div>
  );
}

type ResultsHeaderContentProps = SortFilterProps & {
  title: string;
  isRefinementDisabled?: boolean;
};

function ResultsHeaderContent(props: ResultsHeaderContentProps): ReactElement {
  const filterOptions = props.showChainFilterOptions
    ? props.chainFilterOptions
    : props.categoryFilterOptions;

  const filterByLabel = props.showChainFilterOptions ? 'Chain' : 'Category';

  const getDisplayStringForFilterBy = props.showChainFilterOptions
    ? getDisplayStringForChainType
    : getDisplayStringForScamCategory;

  return (
    <>
      <div className={el`title-filters`}>
        <ResultsTitle>{props.title}</ResultsTitle>
        <Tablet>
          <ResultsSelectedFilter
            selectedFilter={props.selectedFilter}
            onRemoveFilter={props.onRemoveFilter}
          />
        </Tablet>
      </div>

      <div className={el`selects`}>
        <SortBySelect
          className={el`sort-by`}
          isDisabled={props.isRefinementDisabled}
          selectedSort={props.selectedSort}
          onSelectSort={props.onSelectSort}
        />
        {filterOptions && (
          <FilterBy
            className={el`filter-by`}
            isDisabled={props.isRefinementDisabled}
            filterOptions={filterOptions.map((option) => option.filter)}
            onSelectFilter={(filter: Filter) =>
              props.selectedFilter === filter
                ? props.onRemoveFilter?.()
                : props.onAddFilter?.(filter)
            }
            selectedFilter={props.selectedFilter}
            label={`Filter by ${filterByLabel}`}
            getDisplayName={getDisplayStringForFilterBy}
          />
        )}
      </div>
    </>
  );
}

function NoResults(
  props: SortFilterProps & Pick<ResultsSectionProps, 'pageType' | 'error'>
): ReactElement {
  return (
    <>
      <Layout.ResultsHeader>
        <ResultsHeaderContent
          {...props}
          title={`No Reports`}
          isRefinementDisabled={!props.error}
        />
      </Layout.ResultsHeader>
      <Layout.Results>
        <Text type="body-lg" className={el`no-results-message`}>
          {props.error ? props.error : getNoResultsMessage(props.pageType)}
        </Text>
        <FileReportButton className={el`no-results-new-report-button`} />
      </Layout.Results>
    </>
  );
}

export function ResultsSection(props: ResultsSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const hasNoResults = p.numReports === 0 || p.results.length === 0;

  /** Page content when on all results screen */
  const renderAllResults = () => {
    if (hasNoResults) {
      return (
        <NoResults
          {...getSortFilterProps(p)}
          pageType={p.pageType}
          error={p.error}
        />
      );
    }

    return (
      <>
        <Layout.ResultsHeader>
          <ResultsHeaderContent
            {...getSortFilterProps(p)}
            title={`${p.numReports} Scam Reports`}
          />
        </Layout.ResultsHeader>
        <Layout.Results>
          {p.results.map((result, index) => (
            <ScamReportCard
              key={`${result.reportDate}-${index}`}
              {...result}
              {...(result.isPrivate ? PRIVATE_REPORT_DUMMY_DETAILS : {})}
            />
          ))}
          <div className={el`pagination`}>
            <PageSelect
              firstResultOnPageNum={p.firstResultOnPageNum}
              lastResultOnPageNum={p.lastResultOnPageNum}
              numResults={p.numReports}
              count={p.numPages}
              page={p.currentPage}
              onChange={(_, page) => p.onSelectPage?.(page)}
            />
          </div>
        </Layout.Results>
      </>
    );
  };

  return (
    <Layout.Root isNoResults={hasNoResults} className={clsx(ROOT, p.className)}>
      <Layout.Main>
        {p.isLoading ? (
          <CustomContentLoader type="multiple" />
        ) : (
          <>{renderAllResults()}</>
        )}
      </Layout.Main>
      <Layout.Sidebar>
        <FileReportButton className={el`file-report-button`} />
        <Layout.SidebarFilters className="gap-[64px]">
          {p.showReportsByChainNav && <ReportsByChainNav />}
          {p.showReportsByCategoryNav && <ReportsByCategoryNav />}
          {p.showChainFilterOptions && (
            <FilterTable
              type="chain"
              options={p.chainFilterOptions}
              onSelectOption={p.onAddFilter}
              selectedFilter={p.selectedFilter}
              showNoFilters={hasNoResults}
            />
          )}
          {p.showCategoryFilterOptions && (
            <FilterTable
              type="category"
              options={p.categoryFilterOptions}
              onSelectOption={p.onAddFilter}
              selectedFilter={p.selectedFilter}
              showNoFilters={hasNoResults}
            />
          )}
        </Layout.SidebarFilters>
      </Layout.Sidebar>
    </Layout.Root>
  );
}

export type ResultsSectionContainerProps = Pick<
  ResultsSectionProps,
  'pageType' | 'isLoading' | 'onSelectPage'
> &
  UseResultsOptions & {
    username?: string;
  };

const getInitialQueryParams = (
  props: ResultsSectionContainerProps
): UseResultsOptions => {
  const { pageType, ...rest } = props;
  return rest;
};

function ResultsSectionContainer(
  props: ResultsSectionContainerProps
): ReactElement {
  const router = useRouter();
  const {
    results,
    isLoading,
    error,
    page,
    firstResultOnPageNum,
    lastResultOnPageNum,
    totalCount,
    numPages,
    onPageChange,
    selectedSort,
    onSelectSort,
    chainFilterOptions,
    categoryFilterOptions,
    selectedFilter,
    onAddFilter,
    onRemoveFilter,
  } = useResults(getInitialQueryParams(props));

  const shouldShowCategoryFilters =
    props.pageType === PageType.BROWSE_CHAIN ||
    props.pageType === PageType.SEARCH_ADDRESS ||
    props.pageType === PageType.SEARCH_DOMAIN ||
    props.pageType === PageType.PROFILE_SELF ||
    props.pageType === PageType.PROFILE_OTHER ||
    props.pageType === PageType.SUBDOMAIN_FEED;

  const shouldShowChainFilters = props.pageType === PageType.BROWSE_CATEGORY;

  const shouldShowReportsByChainNav = props.pageType === PageType.BROWSE_ALL;

  const shouldShowReportsByCategoryNav = props.pageType === PageType.BROWSE_ALL;

  const handleSelectResult = (reportId: string) => {
    const destination = getRouteForReport(
      reportId,
      props.pageType,
      getHeaderParamsForResults(props)
    );

    router.push(destination);
  };

  const cardsData = results?.map(
    (result): ScamReportCardProps => ({
      ...getScamReportCardPropsFromReport(result),
      onClick: () => handleSelectResult(result.id),
    })
  );

  return (
    <ResultsSection
      error={error ? 'Something went wrong' : undefined}
      pageType={props.pageType}
      results={cardsData ?? []}
      isLoading={isLoading || props.isLoading}
      firstResultOnPageNum={firstResultOnPageNum}
      lastResultOnPageNum={lastResultOnPageNum}
      numReports={totalCount}
      numPages={numPages}
      currentPage={page}
      selectedSort={selectedSort}
      onSelectSort={onSelectSort}
      selectedFilter={selectedFilter}
      onAddFilter={onAddFilter}
      onRemoveFilter={onRemoveFilter}
      showCategoryFilterOptions={
        shouldShowCategoryFilters && categoryFilterOptions !== undefined
      }
      categoryFilterOptions={categoryFilterOptions}
      showChainFilterOptions={
        shouldShowChainFilters && chainFilterOptions !== undefined
      }
      chainFilterOptions={chainFilterOptions}
      showReportsByChainNav={shouldShowReportsByChainNav}
      showReportsByCategoryNav={shouldShowReportsByCategoryNav}
      onSelectPage={props.onSelectPage || onPageChange}
    />
  );
}

export default ResultsSectionContainer;
