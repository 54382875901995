import { PageType } from '@/types/page';
import { QueryParamNames } from '@/types/routes';
import {
  ResultsSectionContainerProps,
  ResultsSectionProps,
  SortFilterProps,
} from './ResultsSection';

export const getSortFilterProps = (
  props: ResultsSectionProps
): SortFilterProps => {
  const {
    selectedFilter,
    onRemoveFilter,
    onAddFilter,
    selectedSort,
    onSelectSort,
    showChainFilterOptions,
    chainFilterOptions,
    showCategoryFilterOptions,
    categoryFilterOptions,
    ...rest
  } = props;

  return {
    selectedFilter,
    onRemoveFilter,
    onAddFilter,
    selectedSort,
    onSelectSort,
    showChainFilterOptions,
    chainFilterOptions,
    showCategoryFilterOptions,
    categoryFilterOptions,
  };
};

/**
 * Gets the query params to include on /report/:reportId
 * to have the page header in PageLayout maintain its state from
 * results
 */
export const getHeaderParamsForResults = (
  props: ResultsSectionContainerProps
): { [key: string]: string } => {
  const contextParams = {
    [QueryParamNames.PAGE_CONTEXT]: props.pageType,
  };

  switch (props.pageType) {
    case PageType.BROWSE_CHAIN: {
      return {
        ...contextParams,
        [QueryParamNames.CHAIN]: props.chain ?? '',
      };
    }
    case PageType.BROWSE_CATEGORY: {
      return {
        ...contextParams,
        [QueryParamNames.CATEGORY]: props.scamCategory ?? '',
      };
    }
    case PageType.SEARCH_ADDRESS: {
      return {
        ...contextParams,
        [QueryParamNames.ADDRESS]: props.address ?? '',
        [QueryParamNames.CHAIN]: props.chain ?? '',
      };
    }
    case PageType.SEARCH_DOMAIN: {
      return {
        ...contextParams,
        [QueryParamNames.DOMAIN]: props.address ?? '',
      };
    }
    case PageType.PROFILE:
    case PageType.PROFILE_SELF:
    case PageType.PROFILE_OTHER: {
      return {
        ...contextParams,
        [QueryParamNames.USERNAME]: props.username ?? '',
      };
    }
    case PageType.MY_FEED:
    case PageType.SUBDOMAIN_FEED:
      return {
        ...contextParams,
        [QueryParamNames.ORG]: props.org?.id ?? '',
      };
    default:
      return contextParams;
  }
};
